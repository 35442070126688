<template>
  <section class="page-issues">
    <div
      v-if="initializing"
      class="flex flex-col justify-center items-center h-screen"
    >
      <van-loading color="#0094ff">
        加载中...
      </van-loading>
    </div>
    <template v-else>
      <div class="flex flex-col w-screen min-h-screen">
        <van-sticky>
          <van-search
            v-model="form.keyword"
            show-action
            label="搜索"
            placeholder="请输入搜索关键字"
            :disabled="refreshing"
            @search="doSearch"
          >
            <template #action>
              <div @click="doSearch">
                搜索
              </div>
            </template>
          </van-search>
          <van-dropdown-menu class="conditions-container border-b-1 border-t-1">
            <van-dropdown-item
              v-model="form.status"
              :options="statuses"
              @change="doSearch"
            />
            <van-dropdown-item
              v-model="form.building"
              :options="buildings"
              @change="doSearch"
            />

            <van-dropdown-item
              v-model="form.source"
              :options="sources"
              @change="doSearch"
            />
          </van-dropdown-menu>
        </van-sticky>
        <van-pull-refresh
          v-if="loading || issues.total"
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="issues.to === null || issues.to == issues.total"
            :finished-text="loading ? '加载中...' : '没有更多了'"
            error-text="加载失败，请下拉页面重新加载"
            :immediate-check="false"
            class="pb-12"
            @load="fetch"
          >
            <van-cell
              v-for="issue of issues.data"
              :key="`issue-${issue._id}`"
              :is-link="true"
              :to="{
                name: 'workspace.issues.edit',
                params: { issue: issue._id }
              }"
              class="issue-item"
            >
              <template #title>
                <p class="van-multi-ellipsis--l2">
                  {{ issue.title }}
                </p>
              </template>
              <template #label>
                <p class="mt-3">
                  问题类别：<span class="font-normal text-black">{{
                    issue.category
                  }}</span>
                </p>
                <p class="mt-3">
                  <span>楼号：<span class="font-normal text-black">{{
                    issue.unit
                  }}</span></span><span
                    v-if="issue.room"
                    class="ml-2"
                  >房间：<span class="font-normal text-black">{{
                    issue.room
                  }}</span></span>
                </p>
                <p class="mt-3">
                  <span
                    v-if="issue.name"
                    class="mr-2"
                  >联系人：<span class="font-normal text-black">{{
                    issue.name
                  }}</span></span><span>电话：<span class="font-normal text-black">{{
                    issue.mobile
                  }}</span></span>
                </p>
                <p class="mt-3">
                  创建时间：<span class="font-normal text-black">{{
                    $dayjs(issue.createdAt).format('YYYY-MM-DD HH:mm:ss')
                  }}</span>
                </p>
                <p class="mt-2 flex flex-row justify-between">
                  <span>状态：<van-tag
                    plain
                    :type="
                      issuesStatus[issue.status]
                        ? issuesStatus[issue.status]
                        : 'primary'
                    "
                    class="py-1 px-2"
                  >
                    {{ issue.status }}
                  </van-tag></span>
                  <span>来源：<span class="font-normal text-black">{{
                    issue.source || '未知'
                  }}</span></span>
                </p>
              </template>
            </van-cell>
          </van-list>
          <div class="button-placeholder" />
        </van-pull-refresh>
        <van-empty
          v-if="!loading && issues.total == 0"
          description="暂无相关意见建议"
        >
          <a
            href="#"
            class="text-red-500"
            @click="reset"
          >清除过滤条件</a>
        </van-empty>
      </div>
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import share from '@/mixins/share'
export default {
  name: 'WorkspaceIssues',
  mixins: [share],
  data() {
    return {
      loading: true,
      initializing: true,
      refreshing: false,
      form: {
        status: '',
        building: '',
        source: '',
        keyword: ''
      },
      issues: {
        current_page: null,
        to: 0,
        total: 0,
        data: []
      }
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb']),
    ...mapState('Common/Config', ['issuesStatus', 'units', 'issuesSources']),
    statuses() {
      return [{ text: '全部状态', value: '' }].concat(
        Object.keys(this.issuesStatus).map(item => ({
          text: item,
          value: item
        }))
      )
    },
    buildings() {
      return [{ text: '全部楼号', value: '' }].concat(
        this.units
          .map(o => {
            delete o.children
            return o
          })
          .map(item => ({ text: item.text, value: item.text }))
      )
    },
    sources() {
      return [{ text: '全部渠道', value: '' }].concat(
        this.issuesSources.map(item => ({ text: item, value: item }))
      )
    }
  },
  mounted() {
    this.fetch()
    this.disableShareMenu()
  },
  methods: {
    async doSearch() {
      this.initializing = true
      let query = Object.assign({}, this.form, {
        _timestamp: +new Date()
      })
      await this.$router.push({ name: 'workspace.issues', query })
      // eslint-disable-next-line no-console
      console.warn('doSearch', this.$route.query)

      this.onRefresh()
    },
    onRefresh() {
      this.refreshing = true
      this.fetch()
    },
    async fetch() {
      if (this.refreshing) {
        // eslint-disable-next-line no-console
        console.warn('这是在刷新页面')
        this.issues = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
        this.refreshing = false
      }
      let query =
        Object.keys(this.$route.query).length > 0
          ? this.$route.query
          : { status: '', building: '', source: '', keyword: '' }
      // eslint-disable-next-line no-console
      console.log('查询信息', query)
      this.form = query
      let page = (this.issues.current_page ?? 0) + 1

      this.loading = true
      try {
        // eslint-disable-next-line no-console
        console.log('开始请求数据', page)
        const response = await this.tcb.callFunction({
          name: 'issues',
          data: Object.assign(
            {
              $url: 'workspaceList',
              page: page
            },
            this.form
          )
        })
        // eslint-disable-next-line no-console
        console.log('函数请求返回', response)
        let { result } = response
        if (result.code == 401) {
          this.$router.replace({ name: 'errors.401' })
          return
        }
        // eslint-disable-next-line no-console
        console.warn('总共有数据', page, response)
        let issues = {
          data: this.issues.data.concat(result.data.data),
          to: this.issues.to + result.data.data.length,
          current_page: page,
          total: result.data.total
        }
        // eslint-disable-next-line no-console
        console.log('数据加载完毕', issues)
        this.$set(this, 'issues', issues)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        this.$notify({ type: 'danger', message: '暂时无法获取意见和建议列表' })
        this.issues = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
      }
      this.loading = false
      this.initializing = false
    },
    reset() {
      this.form = {
        status: '',
        building: '',
        source: '',
        keyword: ''
      }
      this.doSearch()
    }
  }
}
</script>

<style lang="scss" scoped>
.issue-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  .van-cell__value {
    display: none;
  }
}
::v-deep .conditions-container {
  border-top: 1px solid #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  .van-dropdown-menu__bar {
    box-shadow: none !important;
  }
}
::v-deep .van-search--show-action {
  padding-right: 12px;
}
</style>
